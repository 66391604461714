// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from "@chakra-ui/react";
import { accordionTheme } from "./accordionTheme";
const theme = {
    colors: {
        primary: "rebeccapurple",
    },
    config: {
        initialColorMode: "dark",
        useSystemColorMode: true,
    },
    fonts: {
        body: "Montserrat, system-ui, sans-serif",
        heading: "Merryweather, Georgia, serif",
        mono: "Menlo, monospace",
    },
    components: {
        Accordion: accordionTheme,
    },
};

export default extendTheme(theme); // or extendBaseTheme
