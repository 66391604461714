import React, {
    createContext,
    FC,
    ReactNode,
    useEffect,
    useReducer,
} from "react";

export enum Specialization {
    HolyPriest = "Holy Priest",
    DisciplinePriest = "Discipline Priest",
    HolyPaladin = "Holy Paladin",
    MistweaverMonk = "Mistweaver Monk",
    PreservationEvoker = "Preservation Evoker",
    RestorationShaman = "Restoration Shaman",
    RestorationDruid = "Restoration Druid",
}

export const SpecContext = createContext(Specialization.MistweaverMonk);
export const SpecDispatchContext = createContext<React.Dispatch<any>>(
    () => null
);

interface ProviderProps {
    children: ReactNode;
}

const SpecContextProvider: FC<ProviderProps> = ({ children }) => {
    const [spec, specDispatch] = useReducer(specReducer, initialSpec);

    useEffect(() => {
        // @ts-ignore
        if (globalThis.WH) {
            //@ts-ignore
            globalThis.WH.Tooltips.refreshLinks();
        }
    }, [spec]);

    return (
        <SpecContext.Provider value={spec}>
            <SpecDispatchContext.Provider value={specDispatch}>
                {children}
            </SpecDispatchContext.Provider>
        </SpecContext.Provider>
    );
};

function specReducer(
    spec: Specialization,
    action: { type: string; spec: Specialization }
) {
    if (action.type === "update") {
        return action.spec;
    } else {
        throw Error("unknown action");
    }
}

const initialSpec = Specialization.MistweaverMonk;

export default SpecContextProvider;
