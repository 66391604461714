import { accordionAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(accordionAnatomy.keys);

const baseStyle = definePartsStyle({
    // define the part you're going to style
    panel: {
        pl: 0,
        pr: 0,
    },
});

export const accordionTheme = defineMultiStyleConfig({ baseStyle });
