exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-addons-contentful-addon-name-tsx": () => import("./../../../src/pages/addons/{ContentfulAddon.name}.tsx" /* webpackChunkName: "component---src-pages-addons-contentful-addon-name-tsx" */),
  "component---src-pages-articles-contentful-article-title-tsx": () => import("./../../../src/pages/articles/{ContentfulArticle.title}.tsx" /* webpackChunkName: "component---src-pages-articles-contentful-article-title-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/CookiePolicy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-dungeons-contentful-dungeon-name-tsx": () => import("./../../../src/pages/dungeons/{ContentfulDungeon.name}.tsx" /* webpackChunkName: "component---src-pages-dungeons-contentful-dungeon-name-tsx" */),
  "component---src-pages-dungeons-spell-bank-s-3-tsx": () => import("./../../../src/pages/dungeons/SpellBank_S3.tsx" /* webpackChunkName: "component---src-pages-dungeons-spell-bank-s-3-tsx" */),
  "component---src-pages-dungeons-spell-bank-s-4-tsx": () => import("./../../../src/pages/dungeons/SpellBank_S4.tsx" /* webpackChunkName: "component---src-pages-dungeons-spell-bank-s-4-tsx" */),
  "component---src-pages-dungeons-spell-bank-tsx": () => import("./../../../src/pages/dungeons/SpellBank.tsx" /* webpackChunkName: "component---src-pages-dungeons-spell-bank-tsx" */),
  "component---src-pages-howto-contentful-how-to-question-tsx": () => import("./../../../src/pages/howto/{ContentfulHowTo.question}.tsx" /* webpackChunkName: "component---src-pages-howto-contentful-how-to-question-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-contentful-post-title-tsx": () => import("./../../../src/pages/posts/{ContentfulPost.title}.tsx" /* webpackChunkName: "component---src-pages-posts-contentful-post-title-tsx" */),
  "component---src-pages-raid-spell-bank-tsx": () => import("./../../../src/pages/RaidSpellBank.tsx" /* webpackChunkName: "component---src-pages-raid-spell-bank-tsx" */),
  "component---src-pages-specializations-contentful-spec-name-tsx": () => import("./../../../src/pages/specializations/{ContentfulSpec.name}.tsx" /* webpackChunkName: "component---src-pages-specializations-contentful-spec-name-tsx" */)
}

