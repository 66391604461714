import React from "react";
import SpecContextProvider from "./src/context/SpecContext";
import "@fontsource-variable/montserrat";
import "@fontsource/condiment";
import "@fontsource/merriweather";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
    const whTooltips = {
        colorLinks: true,
        iconizeLinks: true,
        renameLinks: true,
    };
    return <SpecContextProvider>{element}</SpecContextProvider>;
};

export const onRouteUpdate = () => {
    if (globalThis.WH) {
        globalThis.WH.Tooltips.refreshLinks();
    }
};
